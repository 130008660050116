<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl text-blue-800 font-bold">Admin User Management</h1>
      <div class="flex justify-end items-end">
        <button
          class="bg-blue-800 py-3 px-6 rounded-md text-white"
          @click="
            $router.push({
              name: 'NewAdmin',
            })
          "
        >
          New Admin
        </button>
      </div>
    </div>
    <div class="flex flex-wrap xl:-mx-1">
      <div class="w-full xl:my-1 xl:px-1 xl:w-1/3">
        <!-- Column Content -->
        <div class="border-2 border-gray-200 p-5 rounded-md">
          <div class="flex justify-between">
            <div>{{ totalAdmins }} ACCOUNTS</div>
            <!-- <div>5 ACCOUNTS</div> -->
          </div>
          <h3 class="text-2xl font-semibold mt-5">Admin</h3>
          <!-- <p class="font-semibold text-blue-800 mt-3 text-sm">
            Learn more
          </p> -->
        </div>
      </div>

      <div class="w-full xl:my-1 xl:px-1 xl:w-1/3">
        <!-- Column Content -->
        <div class="border-2 border-gray-200 p-5 rounded-md">
          <div class="flex justify-between">
            <div>{{ totalSubAdmins }} ACCOUNTS</div>
            <!-- <div>5 ACCOUNTS</div> -->
          </div>
          <h3 class="text-2xl font-semibold mt-5">Sub-Admin</h3>
          <!-- <p class="font-semibold text-blue-800 mt-3 text-sm">
            Learn more
          </p> -->
        </div>
      </div>

      <div class="w-full xl:my-1 xl:px-1 xl:w-1/3">
        <!-- Column Content -->
      </div>
    </div>
    <h4 class="font-semibold mb-5 mt-20 text-xl">Admin Accounts</h4>
    <div class="flex flex-row mt-5 mb-3 justify-end">
      <div class="mr-2">
        <input
          type="text"
          class="inline-block px-2 ring-2 outline-none ring-blue-500 rounded-sm w-64 text-sm py-3"
          name=""
          placeholder="Search User"
          id=""
          v-model="search"
        />
      </div>
      <button
        class="bg-blue-800 uppercase py-2 px-6 rounded-md text-white text-sm mr-2"
        @click="searchRecords"
      >
        Search
      </button>
      <button
        class="bg-red-600 uppercase py-2 px-6 rounded-md text-white text-sm"
        @click="resetSearchRecords"
      >
        Reset
      </button>
    </div>
    <div class="border border-b-2 block">
      <div class="flex justify-start">
        <div
          :class="currentTab === 'all' ? 'border-b-2 border-blue-800 pb-2' : ''"
          @click="moveTab('all')"
          class="cursor-pointer"
        >
          All
          <!-- ({{ filterAdmins.length }}) -->
        </div>
        <div
          class="mx-5 cursor-pointer"
          :class="
            currentTab === 'superadmin' ? 'border-b-2 border-blue-800 pb-2' : ''
          "
          @click="moveTab('superadmin')"
        >
          Admin
          <!-- ({{ totalAdmins }}) -->
        </div>
        <div
          class="cursor-pointer"
          :class="
            currentTab === 'subadmin' ? 'border-b-2 border-blue-800 pb-2' : ''
          "
          @click="moveTab('subadmin')"
        >
          Sub-Admin
          <!-- ({{ totalSubAdmins }}) -->
        </div>
      </div>
    </div>
    <div v-if="currentTab === 'all'">
      <div
        class="mt-5 w-full border-2 border-gray-50 flex flex-col bg-white shadow-lg rounded-lg overflow-auto h-96"
      >
        <table class="shadow-lg w-full relative border-collapse">
          <thead>
            <tr>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Name
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Email Address
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Access type
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Department
              </th>
              <!-- <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Role
            </th> -->
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(admin, i) in filterAdmins" :key="i">
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ admin.first_name + " " + admin.last_name }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ admin.email }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ admin.admin_user_type }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ admin.department }}
              </td>
              <!-- <td class="border px-8 py-3 whitespace-nowrap text-xs">
              <select
                class="inline-block p-3 w-full bg-transparent outline-none text-sm"
                v-model="admin.role"
                @change="handleRoleChange(admin)"
                required
              >
                <option value="SUBADMIN">Sub-Admin</option>
                <option value="ADMIN">Admin</option>
              </select>
            </td> -->
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                <button
                  @click="handleDelete(admin)"
                  class="block uppercase mx-auto hover:bg-red-600 hover:text-white bg-transparent focus:shadow-outline focus:outline-none text-red-600 text-xs font-bold py-2 px-2 rounded float-left"
                  style="width: inherit"
                >
                  Delete
                </button>
                <button
                  @click="handleResetPassword(admin)"
                  class="block uppercase mx-auto hover:bg-green-600 hover:text-white bg-transparent focus:shadow-outline focus:outline-none text-green-600 text-xs font-bold py-2 px-2 rounded float-left"
                  style="width: inherit"
                >
                  Reset Password
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-3">
        <button
          @click="prevPage('all')"
          :disabled="currentPage === 1 ? isDisabled : !isDisabled"
          class="uppercase shadow focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-8 rounded"
          :class="
            currentPage === 1
              ? 'bg-blue-50 text-black hover:bg-blue-600 hover:text-white'
              : 'bg-blue-900 text-white hover:bg-blue-600'
          "
        >
          &laquo; Prev
        </button>

        <button
          @click="nextPage('all')"
          class="float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
          v-if="filterAdmins.length > 99"
        >
          Next &raquo;
        </button>
      </div>
    </div>
    <div v-if="currentTab === 'superadmin'">
      <div
        class="mt-5 w-full border-2 border-gray-50 flex flex-col bg-white shadow-lg rounded-lg overflow-auto h-96"
      >
        <table class="shadow-lg w-full relative border-collapse">
          <thead>
            <tr>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Name
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Email address
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Access type
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Department
              </th>
              <!-- <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Role
            </th> -->
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(admin, i) in filterSupAdmins" :key="i">
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ admin.first_name + " " + admin.last_name }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ admin.email }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ admin.admin_user_type }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ admin.department }}
              </td>
              <!-- <td class="border px-8 py-3 whitespace-nowrap text-xs">
              <select
                class="inline-block p-3 w-full bg-transparent outline-none text-sm"
                v-model="admin.role"
                @change="handleRoleChange(admin)"
                required
              >
                <option value="SUBADMIN">Sub-Admin</option>
                <option value="ADMIN">Admin</option>
              </select>
            </td> -->
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                <button
                  @click="handleDelete(admin)"
                  class="block uppercase mx-auto hover:bg-red-600 hover:text-white bg-transparent focus:shadow-outline focus:outline-none text-red-600 text-xs font-bold py-2 px-2 rounded float-left"
                  style="width: inherit"
                >
                  Delete
                </button>
                <button
                  @click="handleResetPassword(admin)"
                  class="block uppercase mx-auto hover:bg-green-600 hover:text-white bg-transparent focus:shadow-outline focus:outline-none text-green-600 text-xs font-bold py-2 px-2 rounded float-left"
                  style="width: inherit"
                >
                  Reset Password
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-3">
        <button
          @click="prevPage('superadmin')"
          :disabled="
            currentPageSuperAdmin === 1
              ? isDisabledSuperAdmin
              : !isDisabledSuperAdmin
          "
          class="uppercase shadow focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-8 rounded"
          :class="
            currentPageSuperAdmin === 1
              ? 'bg-blue-50 text-black hover:bg-blue-600 hover:text-white'
              : 'bg-blue-900 text-white hover:bg-blue-600'
          "
        >
          &laquo; Prev
        </button>

        <button
          @click="nextPage('superadmin')"
          class="float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
          v-if="filterSupAdmins.length > 99"
        >
          Next &raquo;
        </button>
      </div>
    </div>
    <div v-if="currentTab === 'subadmin'">
      <div
        class="mt-5 w-full border-2 border-gray-50 flex flex-col bg-white shadow-lg rounded-lg overflow-auto h-96"
      >
        <table class="shadow-lg w-full relative border-collapse">
          <thead>
            <tr>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Name
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Email Address
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Access type
              </th>
              <th
                class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
              >
                Department
              </th>
              <!-- <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Role
            </th> -->
              <th
                class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(admin, i) in filterSubAdmins" :key="i">
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ admin.first_name + " " + admin.last_name }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ admin.email }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ admin.admin_user_type }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ admin.department }}
              </td>
              <!-- <td class="border px-8 py-3 whitespace-nowrap text-xs">
              <select
                class="inline-block p-3 w-full bg-transparent outline-none text-sm"
                v-model="admin.role"
                @change="handleRoleChange(admin)"
                required
              >
                <option value="SUBADMIN">Sub-Admin</option>
                <option value="ADMIN">Admin</option>
              </select>
            </td> -->
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                <button
                  @click="handleDelete(admin)"
                  class="block uppercase mx-auto hover:bg-red-600 hover:text-white bg-transparent focus:shadow-outline focus:outline-none text-red-600 text-xs font-bold py-2 px-2 rounded float-left"
                  style="width: inherit"
                >
                  Delete
                </button>
                <button
                  @click="handleResetPassword(admin)"
                  class="block uppercase mx-auto hover:bg-green-600 hover:text-white bg-transparent focus:shadow-outline focus:outline-none text-green-600 text-xs font-bold py-2 px-2 rounded float-left"
                  style="width: inherit"
                >
                  Reset Password
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="mt-3">
        <button
          @click="prevPage('subadmin')"
          :disabled="
            currentPageSubAdmin === 1 ? isDisabledSubAdmin : !isDisabledSubAdmin
          "
          class="uppercase shadow focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-8 rounded"
          :class="
            currentPageSubAdmin === 1
              ? 'bg-blue-50 text-black hover:bg-blue-600 hover:text-white'
              : 'bg-blue-900 text-white hover:bg-blue-600'
          "
        >
          &laquo; Prev
        </button>

        <button
          @click="nextPage('subadmin')"
          class="float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
          v-if="filterSubAdmins.length > 99"
        >
          Next &raquo;
        </button>
      </div>
    </div>
    <div
      v-if="roleChangePrompt"
      class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
    >
      <div
        class="w-1/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4 text-center"
      >
        <div class="block">
          <h4 class="text-center font-semibold">Confirm Role Change</h4>
          <p class="text-xs">
            Confirm Role change to {{ assignRole }} for
            {{ selectedAdmin.email }}
          </p>
          <button
            @click="changeRole"
            class="block uppercase shadow mx-auto hover:bg-blue-600 bg-blue-800 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-2 rounded px-10 mt-5"
            style="width: inherit"
          >
            Confirm
          </button>
          <button
            @click="roleChangePrompt = false"
            class="block uppercase mx-auto hover:bg-red-600 hover:text-white bg-transparent focus:shadow-outline focus:outline-none text-red-600 text-xs font-bold py-2 rounded px-5 mt-3"
            style="width: inherit"
          >
            Close
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="deleteModal"
      class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
    >
      <div
        class="w-1/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4 text-center"
      >
        <div class="block">
          <h4 class="text-center font-semibold">Delete Admin Account</h4>
          <p class="text-xs py-5">
            Confirm account delete for
            {{ selectedAdmin.email }}
          </p>
          <button
            @click="deleteAdmin(selectedAdmin)"
            class="block uppercase shadow mx-auto hover:bg-red-600 bg-red-800 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-2 rounded px-10 mt-5"
            style="width: inherit"
          >
            Delete Account
          </button>
          <button
            @click="deleteModal = false"
            class="block uppercase mx-auto hover:bg-blue-600 hover:text-white bg-transparent focus:shadow-outline focus:outline-none text-blue-600 text-xs font-bold py-2 px-5 rounded mt-3"
            style="width: inherit"
          >
            Close
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="promptDiv"
      class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
    >
      <div
        class="w-1/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4 text-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="currentColor"
          class="bi bi-check2-circle text-green-500 mx-auto"
          viewBox="0 0 16 16"
        >
          <path
            d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"
          />
          <path
            d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"
          />
        </svg>
        <p class="text-sm">
          {{ promptMessage }}
        </p>
        <button
          @click="promptDiv = false"
          class="float-right uppercase shadow mx-auto hover:bg-red-500 bg-red-600 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-2 rounded"
          style="width: inherit"
        >
          Close
        </button>
      </div>
    </div>
    <div
      v-if="resetPrompt"
      class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
    >
      <div
        class="w-1/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4 text-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="currentColor"
          class="bi bi-exclamation-triangle-fill text-red-500 mx-auto"
          viewBox="0 0 16 16"
        >
          <path
            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
          />
        </svg>
        <p class="text-sm">
          Are you sure you want to reset this email's password ({{
            selectedAdmin.email
          }}).
        </p>
        <button
          @click="resetAdmin"
          class="float-right uppercase shadow mx-auto hover:bg-green-500 hover:text-white bg-transparent focus:shadow-outline focus:outline-none text-green-600 text-xs font-bold py-2 px-3 rounded"
        >
          Yes, I Confirm
        </button>
        <button
          @click="resetPrompt = false"
          class="float-right uppercase mx-auto hover:bg-red-500 bg-transparent text-red-500 text-xs font-bold py-2 rounded"
          style="width: inherit"
        >
          Close
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "Admin",
  data() {
    return {
      moment,
      isLoading: false,
      resetPrompt: false,
      isDisabled: true,
      isDisabledSuperAdmin: true,
      isDisabledSubAdmin: true,
      search: "",
      page: 1,
      accounts: null,
      filterToggle: false,
      currentTab: "all",
      selectRole: "subadmin",
      roleChangePrompt: false,
      deleteModal: false,
      selectedAdmin: null,
      assignRole: null,
      selectedRole: null,
      promptMessage: "",
      promptDiv: false,
      subadmins: [],
      superadmins: [],
      currentPage: 1,
      currentPageSubAdmin: 1,
      currentPageSuperAdmin: 1,
      currRole: "admin",
    };
  },
  computed: {
    ...mapGetters(["GET_ALL_ADMINS"]),
    filterAdmins() {
      return this.GET_ALL_ADMINS.data ? this.GET_ALL_ADMINS.data.users : [];
    },
    filterSupAdmins() {
      return this.GET_ALL_ADMINS.data.users.filter((admin) => {
        return admin.admin_user_type === "Admin";
      });
    },
    filterSubAdmins() {
      return this.GET_ALL_ADMINS.data.users.filter((admin) => {
        return admin.admin_user_type !== "ADMIN";
      });
    },
    totalAdmins() {
      const x =
        this.GET_ALL_ADMINS.data &&
        this.GET_ALL_ADMINS.data.users.filter((admin) => {
          return admin.admin_user_type === "ADMIN";
        });
      return x !== undefined ? x.length : 0;
    },
    totalSubAdmins() {
      const x =
        this.GET_ALL_ADMINS.data &&
        this.GET_ALL_ADMINS.data.users.filter((admin) => {
          return admin.admin_user_type !== "ADMIN";
        });
      return x !== undefined ? x.length : 0;
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  created() {
    this.fetchAdmins();
    // this.fetchSuperAdmins();
    // this.fetchSubAdmins();
  },
  methods: {
    async fetchAdmins() {
      this.isLoading = true;
      this.currRole = "";
      let payload = {
        page: this.page,
        role: this.currRole,
        search: this.search,
      };
      try {
        let res = await this.$store.dispatch("FETCH_ADMINS_BY_ROLE", payload);
        if (res.status == true) {
          this.isLoading = false;
          res.users.forEach((el) => {
            if (el.admin_user_type === "Sub-admin") {
              this.subadmins.push(el);
            }
            if (el.admin_user_type === "Admin") {
              this.superadmins.push(el);
            }
          });
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
          $router.go(-1);
        }
      } catch (error) {}

      this.isLoading = false;
    },
    async searchRecords() {
      this.isLoading = true;
      let payload = {
        page: this.page,
        role: this.currRole,
        search: this.search,
      };
      try {
        let res = await this.$store.dispatch("FETCH_ADMINS_BY_ROLE", payload);
        if (res.status == true) {
          this.isLoading = false;
          res.users.forEach((el) => {
            if (el.admin_user_type === "Sub-admin") {
              this.subadmins.push(el);
            }
            if (el.admin_user_type === "Admin") {
              this.superadmins.push(el);
            }
          });
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
          $router.go(-1);
        }
      } catch (error) {}

      this.isLoading = false;
    },
    async resetSearchRecords() {
      this.page = 1;
      this.search = "";
      this.fetchAdmins();
    },
    async fetchSuperAdmins() {
      this.isLoading = true;
      this.currRole = "admin";
      let payload = {
        page: this.page,
        role: this.currRole,
        search: this.search,
      };
      try {
        let res = await this.$store.dispatch("FETCH_ADMINS_BY_ROLE", payload);
        if (res.status == true) {
          this.isLoading = false;
          this.superadmins = res.data;
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
          $router.go(-1);
        }
      } catch (error) {}

      this.isLoading = false;
    },
    async fetchSubAdmins() {
      this.isLoading = true;
      this.currRole = "subadmin";
      let payload = {
        page: this.page,
        role: this.currRole,
        search: this.search,
      };
      try {
        let res = await this.$store.dispatch("FETCH_ADMINS_BY_ROLE", payload);
        if (res.status == true) {
          this.isLoading = false;
          this.subadmins = res.data;
        } else {
          alert(
            res.error ||
              res.message ||
              "Error: Please check network or contact admin."
          );
          $router.go(-1);
        }
      } catch (error) {}

      this.isLoading = false;
    },

    // filterAdmins() {
    //   return this.GET_ALL_ADMINS.data.users;
    // },
    filterSuperAdmins() {
      let admins = [];
      this.GET_ALL_ADMINS.data &&
        this.GET_ALL_ADMINS.data.users.map((el) => {
          if (el.admin_user_type === "ADMIN") {
            admins.push(el);
          }
        });
      return admins;
    },
    filterAllSubAdmins() {
      let admins = [];
      this.GET_ALL_ADMINS.data &&
        this.GET_ALL_ADMINS.data.users.map((el) => {
          if (el.admin_user_type === "SUBADMIN") {
            admins.push(el);
          }
        });
      return admins;
    },
    adminCounters() {
      return this.filterSuperAdmins().length;
    },
    subadminCounters() {
      return this.filterAllSubAdmins().length;
    },
    totalAdminCounter() {
      return this.adminCounters() + this.subadminCounters();
    },
    moveTab(tab) {
      this.currentTab = tab;
      if (tab === "all") {
        this.fetchAdmins();
      } else if (tab === "superadmin") {
        this.fetchSuperAdmins();
      } else {
        this.fetchSubAdmins();
      }
    },
    async changeRole(admin) {
      const response = confirm(
        "Are you sure you want to change this account's role?"
      );
      if (response) {
        this.isLoading = true;
        try {
          let res = await this.$store.dispatch("CHANGE_ROLE_ADMIN", {
            id: this.selectedAdmin._id,
            payload: { role: this.selectedRole },
          });
          this.roleChangePrompt = false;
          if (res.status == true) {
            this.isLoading = false;
            this.promptMessage = res.message;
            this.promptDiv = true;
            this.fetchAdmins();
            this.fetchSuperAdmins();
            this.fetchSubAdmins();
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
            // $router.go(-1);
          }
        } catch (error) {}

        this.isLoading = false;
      }
    },
    async resetAdmin(admin) {
      const response = confirm(
        `Are you sure you want to send a reset link to this email (${this.selectedAdmin.email})?`
      );
      if (response) {
        this.isLoading = true;
        try {
          let res = await this.$store.dispatch("RESET_PASSWORD_ADMIN", {
            email: this.selectedAdmin.email,
          });
          this.resetPrompt = false;
          if (res.status == true) {
            this.isLoading = false;
            this.promptMessage = res.data.message;
            this.promptDiv = true;
            this.fetchAdmins();
            this.fetchSuperAdmins();
            this.fetchSubAdmins();
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
            // $router.go(-1);
          }
        } catch (error) {}

        this.isLoading = false;
      }
    },
    async deleteAdmin(admin) {
      const response = confirm("Are you sure you want to delete this account?");
      if (response) {
        this.isLoading = true;
        try {
          let res = await this.$store.dispatch("DELETE_ADMIN", {
            id: admin._id,
          });
          if (res.status == true) {
            this.isLoading = false;
            this.promptMessage = res.message;
            this.deleteModal = false;
            this.promptDiv = true;
            this.fetchAdmins();
            this.fetchSuperAdmins();
            this.fetchSubAdmins();
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
            $router.go(-1);
          }
        } catch (error) {}

        this.isLoading = false;
      }
    },
    handleDelete(admin) {
      this.deleteModal = true;
      this.selectedAdmin = admin;
    },
    handleRoleChange(admin) {
      // console.log("Welcome");
      this.roleChangePrompt = true;
      // this.changeRole(admin);
      this.selectedAdmin = admin;
      this.selectedRole = admin.role;
      this.assignRole = admin.role;
    },
    handleResetPassword(admin) {
      this.selectedAdmin = admin;
      this.resetPrompt = true;
    },
    async nextPage(adminType) {
      this.isLoading = true;

      let currPage =
        adminType === "subadmin"
          ? this.currentPageSubAdmin
          : adminType == "superadmin"
          ? this.currentPageSuperAdmin
          : this.currentPage;
      let role =
        adminType === "subadmin"
          ? "subadmin"
          : adminType == "superadmin"
          ? "admin"
          : "";

      this.currRole = role;

      const payload = {
        page: currPage + 1,
        search: this.search,
        role,
      };
      try {
        const res = await this.$store.dispatch("FETCH_ADMINS_BY_ROLE", payload);
        this.isLoading = false;
        if (res.status) {
          this.currentPage += 1;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async prevPage(adminType) {
      this.isLoading = true;
      let currPage =
        adminType === "subadmin"
          ? this.currentPageSubAdmin
          : adminType == "superadmin"
          ? this.currentPageSuperAdmin
          : this.currentPage;
      let role =
        adminType === "subadmin"
          ? "subadmin"
          : adminType == "superadmin"
          ? "admin"
          : "";
      this.currRole = role;

      const payload = {
        page: currPage - 1,
        role,
        search: this.search,
      };
      try {
        const res = await this.$store.dispatch("FETCH_ADMINS_BY_ROLE", payload);
        this.isLoading = false;
        if (res.status) {
          this.currentPage -= 1;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(241, 241, 241);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(0, 0, 0);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
  color: #fff;
  position: sticky;
  top: 0;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
